import * as React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from "@mdx-js/react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SSRProvider from 'react-bootstrap/SSRProvider'

import BasicLearnMorePanel from '../components/basicLearnMorePanel'
import DefaultHeader from '../components/default-header'
import Footer from '../components/footer'
import GdprPanel from '../components/gdpr'
import Navigation from '../components/navigation'
import Seo from '../components/seo'
import NewsletterSignup from '../components/newsletterSignUp'



export default function ConsumerInformationPage({ data }) {

    const post = data.mdx

    return (      
        <>  
        <SSRProvider>
            <Navigation />
            <DefaultHeader type="xl" headline={post.frontmatter.headline} heroBackground={post.frontmatter.heroBackground} heroMobileBackground={post.frontmatter.heroMobileBackground} subHeadline={post.frontmatter.subheadline} />
            <div id="body">
                <Container fluid="lg">
                    <Row>
                        <Col lg={9} md={8} className="pe-md-5 mb-md-5 mb-3">
                            <Row className="white-bg mb-5"> 
                                <Col className="p-4">
                                    <MDXProvider>
                                        <MDXRenderer>{post.body}</MDXRenderer>
                                    </MDXProvider>   
                                </Col>
                            </Row>   
                        </Col>
                        <Col lg={3} md={4} >
                            <BasicLearnMorePanel menuType="basic" />
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
            <GdprPanel />
            <NewsletterSignup />
        </SSRProvider>
        </>
    )
}

export const Head = ({data}) => (
    <Seo 
        title={data.mdx.frontmatter.metadata.Title} 
        description={data.mdx.frontmatter.metadata.Description} 
        pathname={data.mdx.frontmatter.metadata.Link}
        ogImage={data.mdx.frontmatter.metadata.ogImage}
        cardImage={data.mdx.frontmatter.metadata.cardImage} 
    />
)

export const query = graphql`
    query($slug: String!) {
        mdx(slug: { eq: $slug }) {
            frontmatter {
                heroBackground
                heroMobileBackground
                subheadline
                headline
                title
                metadata {
                    Description
                    Keywords
                    Link
                    Title
                    ogImage
                    cardImage
                }
            }
            body
        }
    }   
`